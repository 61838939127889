const firebaseConfig = {
    apiKey: "AIzaSyBaAwSjXYeTjGIFphOWijZt8x6KxClLBYU",
    authDomain: "stg-sso-fb.firebaseapp.com",
    projectId: "stg-sso-fb",
    storageBucket: "stg-sso-fb.appspot.com",
    messagingSenderId: "466632231055",
    appId: "1:466632231055:web:c6f830c6cfaf9a6df7c8fa",
    measurementId: "G-VXDEPFTWXZ"
}
//Production
// const firebaseConfig = {
//   apiKey: "AIzaSyABHxN61qcOy3LXxpm7jVDL-LE6XCQm5Y4",
//   authDomain: "prod-sso-firebase.firebaseapp.com",
//   projectId: "prod-sso-firebase",
//   storageBucket: "prod-sso-firebase.appspot.com",
//   messagingSenderId: "203228316218",
//   appId: "1:203228316218:web:788d08a2c883e7e26d6b03",
// }
export default firebaseConfig;
